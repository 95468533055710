@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@100;300&display=swap');

* {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
*, ::after, ::before {
    box-sizing: border-box;
}

html,
body,
#root,
.buddi-app {
  width: 100vw;
  height: 100vh;
}

.buddi {
	--fontPrimary: 'Roboto', sans-serif;
	--fontSecondary: 'Lato', sans-serif;

	--headerViewHeight: 50px;
	--textSizePrimary: 1rem;

	--gapSmall: .5rem;

	--paddingXSmall: .5rem;
	--paddingSmall: .8rem;

	--border-diver-color: #E3E3E3;
	--menuBackgroundColor: #FFF;
	--menuActiveBackgroundColor: #F3F3F3;

	--iconSizeSmall: 25px;
}
.buddi.themeLight {
	--primaryTextColor: #444;
	/* --menuBackgroundColor: #FAFAFA; */
}
.buddi.themeDark {
	--primaryTextColor: #EEE;

	--border-diver-color: #303030;
	--menuBackgroundColor: #181818;
	--menuActiveBackgroundColor: #303030;
}

.buddi * {
	font-family: inherit;
	font-size: inherit;
	line-height: 1;
}

.buddi .no-select {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.buddi svg {display: inline-block;}
.buddi button {
	padding: 0;
	margin: 0;
	border: none;
}
.buddi input,
.buddi select,
.buddi textarea {
  outline: none;
}
.buddi *:focus {
    outline: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #333;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #333;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #333;
}



.buddi-list {
	display: flex;
}
.buddi-list.flexRow {
	flex-direction: row;
}
.buddi-list.flexCol {
	flex-direction: column;
}
.buddi-list.gapSmall {
	gap: var(--gapSmall);
}
